import {
    ActivityIndicator,
    Image,
    View,
    useWindowDimensions,
} from 'react-native';
import { AuthStateEnum, api } from '../../../App.context';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import React, { useEffect, useState } from 'react';
import { Switch, Text } from 'react-native-elements';
import {
    useFocusEffect,
    useNavigation,
    useRoute,
} from '@react-navigation/native';
import { CheckListItem } from '../../common/ui/elements/check-list-item.element';
import { CreditCardTile } from '../accounts/components/cards/credit-card-tile.component';
import { Icon } from 'react-native-elements/dist/icons/Icon';
import PropTypes from 'prop-types';
import { ScrollableScreenLayout } from '../../common/ui/components/screen-layout.component';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { debounce } from 'lodash';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { isAndroid } from '../../common/utils/platform.utils';
import { useAppSelector } from '../../hooks/store';

export const ManageCardScreen = () => {
    const navigation = useNavigation();
    const {
        params: { account },
    } = useRoute();
    const {authState, profile} = useAppSelector((state) => state.users)
    const toast = useToast();
    const [cards, setCards] = useState([]);
    const [lockedCard, setLockedCard] = useState(false);
    const [selectedCard, setSelectedCard] = useState();

    const allowCardActions =
        authState === AuthStateEnum.Customer_Active &&
        profile.kyc_status !== 'REVIEW';

    // Retrieves an array of cards for the given account
    const fetchCards = () => {
        api.get(`/card/${account.id}`)
            .then(({ cards }) => setCards([...cards, { id: null }]))
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    useFocusEffect(
        React.useCallback(() => {
            fetchCards();
        }, [])
    );

    const guardedCardAction = (cardAction) => {
        if (!allowCardActions) {
            toast.show(i18n.t('pages.kycInReview.verificationRequired'), {
                type: 'dangerWithTitle',
                data: {
                    title: i18n.t('pages.kycInReview.verifyIdentity'),
                },
            });
        } else {
            cardAction();
        }
    };

    const reportLostOrStolen = () =>
        guardedCardAction(() => {
            toast.show(
                i18n.t('pages.home.manageCard.lostOrStolen.successMessage'),
                {
                    type: 'infoWithTitle',
                    data: {
                        title: i18n.t(
                            'pages.home.manageCard.lostOrStolen.successTitle'
                        ),
                    },
                }
            );
        });

    const addToApplePay = () =>
        guardedCardAction(() => {
            toast.show(
                i18n.t('pages.home.manageCard.addToApplePay.successMessage'),
                {
                    type: 'infoWithTitle',
                    data: {
                        title: i18n.t(
                            'pages.home.manageCard.addToApplePay.successTitle'
                        ),
                    },
                }
            );
        });

    const updateCardLocked = (lock) =>
        guardedCardAction(() => {
            setLockedCard(lock);

            let action = lock ? 'suspend' : 'unsuspend';
            api.put(`/card/${selectedCard.id}/${action}`)
                .then((response) => {
                    updateSelectedCard(response);
                    toast.show(
                        i18n.t(
                            `pages.home.manageCard.lockCard.${action}CardSuccessMessage`
                        ),
                        {
                            type: 'successWithTitle',
                            data: {
                                title: i18n.t(
                                    `pages.home.manageCard.lockCard.successTitle`
                                ),
                            },
                        }
                    );
                })
                .catch((err) => {
                    setLockedCard(!lock);
                    toast.show(err.message, {
                        type: 'dangerWithTitle',
                        data: {
                            title: i18n.t('common.errorTitle'),
                        },
                    });
                });
        });

    const updateSelectedCard = (newCardInfo) => {
        let updatedCardInfo = {
            ...selectedCard,
            ...newCardInfo,
        };
        setCards(
            cards.map((c) => (c.id === selectedCard.id ? updatedCardInfo : c))
        );
    };

    const selectCard = (card) => {
        if (card) {
            setSelectedCard(card);
            setLockedCard(card.card_status === 'SUSPENDED');
        }
    };

    const manageCardView = (selectedCard) => {
        if (
            selectedCard &&
            selectedCard.id &&
            selectedCard.card_status === 'UNACTIVATED'
        ) {
            return (
                <View>
                    <Text h2>{i18n.t('pages.home.manageCard.subtitle')}</Text>

                    <ActionItem
                        title={i18n.t(
                            'pages.home.manageCard.actions.viewPhysicalCardTitle'
                        )}
                        description={i18n.t(
                            'pages.home.manageCard.actions.viewPhysicalCardDesc'
                        )}
                        iconName="credit-card"
                        onClick={() =>
                            navigation.push('Home.PhysicalCardDetails', {
                                selectedCard,
                            })
                        }
                        disabled={false}
                    />

                    <ActionItem
                        title={i18n.t(
                            'pages.home.manageCard.actions.activateCardTitle'
                        )}
                        description={i18n.t(
                            'pages.home.manageCard.actions.activateCardDesc'
                        )}
                        iconName="credit-card"
                        onClick={() =>
                            navigation.push('Home.ActivateCard', {
                                selectedCard,
                                account,
                            })
                        }
                        disabled={false}
                    />
                </View>
            );
        } else if (selectedCard && selectedCard.id) {
            return (
                <View>
                    <Text h2>{i18n.t('pages.home.manageCard.subtitle')}</Text>

                    <ActionItem
                        title={i18n.t(
                            'pages.home.manageCard.actions.lockCardTitle'
                        )}
                        description={i18n.t(
                            'pages.home.manageCard.actions.lockCardDesc'
                        )}
                        iconName="lock"
                        onClick={() => console.log('Item CLick')}
                        disabled={true}
                    >
                        <Switch
                            value={lockedCard}
                            onValueChange={debounce(updateCardLocked, 2000, {
                                leading: true,
                            })}
                            color={theme.colors.phthaloBlue}
                        />
                    </ActionItem>
                    <ActionItem
                        title={i18n.t(
                            'pages.home.manageCard.actions.lostCardTitle'
                        )}
                        description={i18n.t(
                            'pages.home.manageCard.actions.lostCardDesc'
                        )}
                        iconName="rotate-right"
                        onClick={reportLostOrStolen}
                        disabled={false}
                    />
                    <ActionItem
                        title={isAndroid() ? i18n.t(
                            'pages.home.manageCard.actions.addCardTitleAndroid'
                        ) : i18n.t(
                            'pages.home.manageCard.actions.addCardTitle'
                        )}
                        onClick={addToApplePay}
                        disabled={false}
                    />
                </View>
            );
        }
    };

    return (
        <ScrollableScreenLayout>
            <View style={{ flexDirection: 'column' }}>
                <CardsCarousel
                    account={account}
                    cards={cards}
                    onChange={selectCard}
                    orderCardInterceptor={guardedCardAction}
                />
                {selectedCard && !selectedCard.id && (
                    <View>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 30,
                            }}
                        >
                            <Image
                                source={require('../../../assets/images/creditcard.png')}
                                style={{
                                    width: 25,
                                    height: 25,
                                    marginBottom: 12,
                                }}
                            />
                            <Text h2 style={{ marginLeft: 10 }}>
                                {i18n.t('pages.home.manageCard.newCard.title')}
                            </Text>
                        </View>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 20,
                                letterSpacing: 0.4,
                                marginVertical: 15,
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.newCard.description'
                            )}
                        </Text>
                        <CheckListItem
                            label={i18n.t(
                                'pages.home.manageCard.newCard.item1'
                            )}
                            size={12}
                            color="rgba(0, 0, 0, 0.6)"
                            designIcon={require('../../../assets/images/monetizationOn.png')}
                        />
                        <CheckListItem
                            label={i18n.t(
                                'pages.home.manageCard.newCard.item2'
                            )}
                            size={12}
                            color="rgba(0, 0, 0, 0.6)"
                            designIcon={require('../../../assets/images/security.png')}
                        />
                        <CheckListItem
                            label={i18n.t(
                                'pages.home.manageCard.newCard.item3'
                            )}
                            size={12}
                            color="rgba(0, 0, 0, 0.6)"
                            designIcon={require('../../../assets/images/shoppingCart.png')}
                        />
                    </View>
                )}

                {manageCardView(selectedCard)}
            </View>
        </ScrollableScreenLayout>
    );
};

const ActionItem = ({
    title,
    description,
    iconName,
    children,
    onClick,
    disabled,
}) => {
    return (
        <View style={theme.styles.mediumBlueBorder}>
            <TouchableOpacity
                onPress={() => onClick()}
                disabled={disabled}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <View style={{ flex: 1 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                            {iconName ?
                                <Icon
                                    name={iconName}
                                    size={22}
                                    color={theme.colors.phthaloBlue}
                                /> :
                                isAndroid() ? <Image
                                    source={require('../../../assets/images/googlePay.png')}
                                    style={{
                                        width: 40,
                                        height: 31,
                                    }}
                                /> : <Image
                                    source={require('../../../assets/images/applePay.png')}
                                    style={{
                                        width: 40,
                                        height: 31,
                                    }}
                                />
                            }
                            <Text
                                style={{
                                    fontFamily: 'DMSans_700Bold',
                                    fontSize: 17,
                                    marginLeft: 10,
                                    lineHeight: 20,
                                    color: 'rgba(0, 0, 0, 0.87)'
                                }}
                            >
                                {title}
                            </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>{children}</View>
                    </View>
                    {description ? <Text
                        style={{
                            fontFamily: 'DMSans_400Regular',
                            marginTop: 10,
                            fontSize: 12,
                            lineHeight: 20,
                            letterSpacing: 0.4,
                            color: 'rgba(0, 0, 0, 0.87)'
                        }}
                    >
                        {description}
                    </Text> : isAndroid() ? <Image
                        source={require('../../../assets/images/googlePayWallet.png')}
                        style={{
                            width: 215,
                            height: 38,
                            marginTop: 10
                        }}
                    /> : <Image
                        source={require('../../../assets/images/applePayWallet.png')}
                        style={{
                            width: 164,
                            height: 34,
                            marginTop: 10
                        }}
                    />}
                </View>
            </TouchableOpacity>
        </View>
    );
};

ActionItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.any,
    iconName: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

const CardsCarousel = ({ account, cards, onChange, orderCardInterceptor }) => {
    const { width } = useWindowDimensions();
    const [activeIndex, setActiveIndex] = useState(0);

    const selectCard = (index) => {
        if (cards !== null) {
            setActiveIndex(index);
            onChange(cards[index]);
        }
    };

    useEffect(() => selectCard(activeIndex), [cards]);

    if (cards === null) {
        return (
            <View
                style={{
                    height: 180,
                    justifyContent: 'center',
                }}
            >
                <ActivityIndicator />
            </View>
        );
    }
    return (
        <View>
            <Carousel
                containerCustomStyle={{
                    marginLeft: -25,
                    marginTop: 10,
                    height: 208,
                }}
                data={cards}
                inactiveSlideScale={0.85}
                inactiveSlideOpacity={0.35}
                inactiveSlideShift={10}
                renderItem={(c) => (
                    <CreditCardTile
                        account={account}
                        card={c.item}
                        orderCardInterceptor={orderCardInterceptor}
                    />
                )}
                sliderWidth={width}
                itemWidth={width * 0.88}
                onSnapToItem={(index) => selectCard(index)}
            />
            <Pagination
                dotColor={theme.colors.textBody}
                inactiveDotColor={theme.colors.tabBarInactiveTint}
                activeDotIndex={activeIndex}
                dotsLength={cards.length}
            />
        </View>
    );
};

CardsCarousel.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
    }),
    cards: PropTypes.array,
    onChange: PropTypes.func,
    orderCardInterceptor: PropTypes.func,
};
