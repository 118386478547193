import {
    Alert,
    SafeAreaView,
    ScrollView,
    View,
    useWindowDimensions,
} from 'react-native';
import { Button, CheckBox, Icon } from 'react-native-elements';
import { api } from '../../../App.context';
import React, { useState } from 'react';
import { Asset } from 'expo-asset';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import RenderHtml from 'react-native-render-html';
import i18n from 'i18n-js';
import { isWeb } from '../../common/utils/platform.utils';
import moment from 'moment';
import theme from '../../common/ui/theme';
import { useFormContext } from 'react-hook-form';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { useAuth0Actions } from '../../hooks/useAuth0Actions';
import { useAppSelector } from '../../hooks/store';
import WebView from 'react-native-webview';
// TODO: refactor this screen .

const createCustomer = (authUserInfo, formData) => {
    const body = {
        ...formData,
        email: authUserInfo.email,
        dob: moment(formData.dob, 'MM/DD/YYYY').format('YYYY-MM-DD'),
        phone_number: formData.phone_number.replace(/[\s-]/g, ''),
        kyc_status: null,
        status: 'ACTIVE',
    };
    console.log('⛑️body', body);
    return api.post('/customer', { body });
};

const selectProduct = (formData) => {
    return api.post('/accounts', {
        body: {
            account_template_id: formData.productId,
        },
    });
};

const acceptTerms = () => {
    const body = {
        disclosure_type: 'TERMS_AND_CONDITIONS',
        event_type: 'ACKNOWLEDGED',
        version: '1.0',
    };
    return api.post('/disclosures', { body });
};

const verifyKYC = () => {
    return api.post('/verify', {});
};

export const Disclosures = () => {
    const { user } = useAppSelector((state) => state.users);
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const { width, height } = useWindowDimensions();
    const disclosures = Asset.fromModule(
        require('../../../assets/disclosures.html')
    ).uri;
    const { refreshSession } = useAuth0Actions();
    const { getValues } = useFormContext();
    const toast = useToast();

    const submit = async () => {
        const formData = getValues();
        setLoading(true);
        let cust;
        try {
            cust = await createCustomer(user, formData);
            console.log('1 createCustomer' + JSON.stringify(cust));
        } catch (err) {
            console.error('1 Error in createCustomer', JSON.stringify(err));
            setLoading(false);
            isWeb()
                ? toast.show(err.message, {
                      type: 'dangerWithTitle',
                      data: {
                          title: i18n.t('common.errorTitle'),
                      },
                  })
                : Alert.alert(i18n.t('common.errorTitle'), err.message);
        }

        if (cust) {
            await refreshSession({ shouldReload: false });
        }

        let account;
        let termsAndConditions;
        let verifyKyc;

        if (cust && cust.kyc_status !== 'REJECTED' && cust.kyc_status !== '') {
            account = await selectProduct(formData).catch((error) => {
                isWeb()
                    ? toast.show(error.message, {
                          type: 'dangerWithTitle',
                          data: {
                              title: i18n.t('common.errorTitle'),
                          },
                      })
                    : Alert.alert(i18n.t('common.errorTitle'), error.message);
            });
            if (account) {
                termsAndConditions = await acceptTerms().catch((error) => {
                    isWeb()
                        ? toast.show(error.message, {
                              type: 'dangerWithTitle',
                              data: {
                                  title: i18n.t('common.errorTitle'),
                              },
                          })
                        : Alert.alert(
                              i18n.t('common.errorTitle'),
                              error.message
                          );
                });
            }
            if (termsAndConditions) {
                verifyKyc = await verifyKYC().catch((error) => {
                    isWeb()
                        ? toast.show(error.message, {
                              type: 'dangerWithTitle',
                              data: {
                                  title: i18n.t('common.errorTitle'),
                              },
                          })
                        : Alert.alert(
                              i18n.t('common.errorTitle'),
                              error.message
                          );
                });
            }
            if (verifyKyc) {
                await refreshSession({ shouldReload: true });
            }
        }
    };

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <OnboardingPageLayout
                step={5}
                of={5}
                title={i18n.t('pages.disclosures.title')}
            >
                <ScrollView
                    style={{
                        marginVertical: 25,
                        height: isWeb() ? height - 350 : height - 300,
                    }}
                >
                    <WebView
                        originWhitelist={['*']}
                        containerStyle={{
                            display: 'flex',
                            flex: 1,
                            height: height,
                            width: width,
                            padding: 20,
                        }}
                        source={{
                            uri: `https://terms-and-conditions-sample.vercel.app/`,
                        }}
                    />
                </ScrollView>
                <View>
                    <CheckBox
                        uncheckedIcon={
                            <Icon
                                color={theme.colors.phthaloBlue}
                                type="ionicon"
                                name="square-outline"
                            />
                        }
                        checkedIcon={
                            <Icon
                                color={theme.colors.phthaloBlue}
                                type="ionicon"
                                name="checkbox"
                            />
                        }
                        containerStyle={webViewDesignSupport}
                        checked={checked}
                        onPress={() => setChecked(!checked)}
                        title={i18n.t('pages.disclosures.agreedLabel')}
                    />
                    <Button
                        buttonStyle={theme.styles.buttonStylePrimary}
                        titleStyle={theme.styles.titleStylePrimary}
                        loading={loading}
                        disabled={!checked}
                        onPress={() => submit()}
                        title={i18n.t('common.submit')}
                        style={webViewDesignSupport}
                    />
                </View>
            </OnboardingPageLayout>
        </SafeAreaView>
    );
};
