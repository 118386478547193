import { Button, Text } from 'react-native-elements';
import { api } from '../../../../../App.context';
import { View, useWindowDimensions } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ModalLayout } from '../../../../common/ui/components/modal-layout.component';
import PropTypes from 'prop-types';
import React from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';
import i18n from 'i18n-js';
import { isWeb } from '../../../../common/utils/platform.utils';
import moment from 'moment';
import theme from '../../../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { useAppSelector } from '../../../../hooks/store';

export const OrderPhysicalCard = () => {
    const { width } = useWindowDimensions();
    const navigation = useNavigation();
    const { profile } = useAppSelector((state) => state.users);
    const {
        params: { account },
    } = useRoute();
    const toast = useToast();

    const estimatedDeliveryDate = moment().add(10, 'day').format('LL');

    const navigateToPhysicalCardOrdered = (response) => {
        navigation.push('Home.PhysicalCardOrdered', { response, account });
        toast.show(
            i18n.t('pages.home.manageCard.physicalCardOrdered.successBody'),
            {
                type: 'successWithTitle',
                data: {
                    title: i18n.t(
                        'pages.home.manageCard.physicalCardOrdered.successTitle'
                    ),
                },
            }
        );
    };

    const orderPhysicalCard = () => {
        api.post('/card', {
            body: {
                account_id: `${account.id}`,
                form: 'PHYSICAL',
                shipping_address: {
                    address_line_1: `${profile.shipping_address.address_line_1}`,
                    city: `${profile.shipping_address.city}`,
                    country_code: `${profile.shipping_address.country_code}`,
                    postal_code: `${profile.shipping_address.postal_code}`,
                    state: `${profile.shipping_address.state}`,
                },
            },
        })
            .then((response) => {
                navigateToPhysicalCardOrdered(response);
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    return (       
                <ModalLayout
                    title={i18n.t(
                        'pages.home.manageCard.orderPhysicalCard.title'
                    )}
                    subtitle={i18n.t(
                        'pages.home.manageCard.orderPhysicalCard.item1'
                    )}
                >
                    <View>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 28,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.orderPhysicalCard.item2'
                            )}
                            &#58;
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {profile && profile.shipping_address.address_line_1}
                        </Text>
                    </View>
                    <View>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {profile && profile.shipping_address.city}&#44;
                            {profile && profile.shipping_address.state}&#44;
                            {profile && profile.shipping_address.postal_code}
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <TouchableOpacity
                            onPress={() =>
                                navigation.push('Home.EditAddress', { profile })
                            }
                        >
                            <Text
                                style={{
                                    fontSize: 15,
                                    fontFamily: 'DMSans_500Medium',
                                    lineHeight: 26,
                                    letterSpacing: 0.46,
                                    textDecorationLine: 'underline',
                                    color: '#00008E',
                                }}
                            >
                                {i18n.t(
                                    'pages.home.manageCard.orderPhysicalCard.item3'
                                )}
                            </Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: 30 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_700Bold',
                                lineHeight: 28,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.orderPhysicalCard.item4'
                            )}
                            &#58;
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 16,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 24,
                                letterSpacing: 0.15,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            {estimatedDeliveryDate}
                        </Text>
                    </View>
                    <View style={{ marginTop: 10 }}>
                        <Text
                            style={{
                                fontSize: 12,
                                fontFamily: 'DMSans_400Regular',
                                lineHeight: 20,
                                letterSpacing: 0.4,
                                color: 'rgba(0, 0, 0, 0.6)',
                            }}
                        >
                            {i18n.t(
                                'pages.home.manageCard.orderPhysicalCard.item5'
                            )}
                        </Text>
                    </View>
                    <View
                        style={{
                            alignItems: 'center',
                            flex: 1,
                        }}
                    >
                        <View
                            style={{
                                bottom: 20,
                                position: isWeb() ? 'fixed' : 'absolute',
                                width: width - 50,
                                marginTop: 30,
                                marginBottom: 15,
                            }}
                        >
                            <Button
                                buttonStyle={theme.styles.buttonStylePrimary}
                                titleStyle={theme.styles.titleStylePrimary}
                                loadingProps={{
                                    color: theme.colors.textHeading,
                                }}
                                title={i18n.t('common.order')}
                                onPress={() => orderPhysicalCard()}
                            />
                        </View>
                    </View>
                </ModalLayout>
           
        
    );
};

OrderPhysicalCard.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.string,
        legal_address: PropTypes.shape({
            address_line_1: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            postal_code: PropTypes.string,
        }),
    }),
    onPress: PropTypes.func,
};
