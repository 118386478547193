import { Button, CheckBox, Icon, Input, Text } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import { api } from '../../../App.context';
import React, { useState } from 'react';
import { Dropdown } from 'react-native-element-dropdown';
import { ScrollableScreenLayout } from '../../common/ui/components/screen-layout.component';
import { View } from 'react-native';
import i18n from 'i18n-js';
import { isEqual } from 'lodash';
import provinces from 'provinces-ca';
import states from 'states-us';
import theme from '../../common/ui/theme';
import { useRoute } from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { useAppDispatch } from '../../hooks/store';
import { profileState } from '../../store/auth0/slice';

export const EditAddressesScreen = () => {
    const {
        params: { profile },
    } = useRoute();

    const [sameAddress, setSameAddress] = useState(
        isEqual(profile.legal_address, profile.shipping_address)
    );

    const {
        control,
        handleSubmit,
        formState: { isValid, errors },
    } = useForm({
        defaultValues: {
            'legal_address.address_line_1':
                profile.legal_address.address_line_1,
            'legal_address.address_line_2':
                profile.legal_address.address_line_2,
            'legal_address.city': profile.legal_address.city,
            'legal_address.postal_code': profile.legal_address.postal_code,
            'legal_address.state': profile.legal_address.state,
            'shipping_address.address_line_1':
                profile.shipping_address.address_line_1,
            'shipping_address.address_line_2':
                profile.shipping_address.address_line_2,
            'shipping_address.city': profile.shipping_address.city,
            'shipping_address.postal_code':
                profile.shipping_address.postal_code,
            'shipping_address.state': profile.shipping_address.state,
        },
    });

    const toast = useToast();

    const focusInput = true;

    const dispatch = useAppDispatch();

    const onSubmit = (formData) => {
        const legal_address = {
            address_line_1: formData.legal_address.address_line_1,
            address_line_2: formData.legal_address.address_line_2,
            city: formData.legal_address.city,
            postal_code: formData.legal_address.postal_code,
            state: formData.legal_address.state,
            country_code: profile.legal_address.country_code,
        };
        const shipping_address = sameAddress
            ? legal_address
            : {
                address_line_1: formData.shipping_address.address_line_1,
                address_line_2: formData.shipping_address.address_line_2,
                city: formData.shipping_address.city,
                postal_code: formData.shipping_address.postal_code,
                state: formData.shipping_address.state,
                country_code: profile.shipping_address.country_code,
            };
        const body = {
            legal_address,
            shipping_address,
        };

        return api
            .patch('/customer', { body })
            .then((response) => {
                dispatch(profileState(response))
                toast.show(i18n.t('pages.profile.editAddress.toast.success'), {
                    type: 'successWithTitle',
                });
            })
            .catch((err) => {
                toast.show(err.message, {
                    type: 'dangerWithTitle',
                    data: {
                        title: i18n.t('common.errorTitle'),
                    },
                });
            });
    };

    return (
        <ScrollableScreenLayout>
            <View>
                <View>
                    <Text h2>{i18n.t('pages.profile.homeAddress')}</Text>
                    <Controller
                        control={control}
                        name="legal_address.address_line_1"
                        rules={{
                            required: {
                                value: 'true',
                                message: i18n.t('forms.common.required'),
                            },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                errorMessage={
                                    focusInput &&
                                    errors.legal_address &&
                                    errors.legal_address.address_line_1 &&
                                    errors.legal_address.address_line_1.message
                                }
                                label={i18n.t('forms.streetAddress1.label')}
                                placeholder={i18n.t(
                                    'forms.streetAddress1.placeholder'
                                )}
                                onChangeText={onChange}
                                onBlur={onBlur}
                                value={value}
                                style={webViewDesignSupport}
                                autoFocus={true}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="legal_address.address_line_2"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                errorMessage={
                                    errors.legal_address &&
                                    errors.legal_address.address_line_2 &&
                                    errors.legal_address.address_line_2.message
                                }
                                label={i18n.t('forms.streetAddress2.label')}
                                placeholder={i18n.t(
                                    'forms.streetAddress2.placeholder'
                                )}
                                onChangeText={onChange}
                                onBlur={onBlur}
                                value={value}
                                style={webViewDesignSupport}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="legal_address.city"
                        rules={{
                            required: {
                                value: 'true',
                                message: i18n.t('forms.common.required'),
                            },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                errorMessage={
                                    errors.legal_address &&
                                    errors.legal_address.city &&
                                    errors.legal_address.city.message
                                }
                                label={i18n.t('forms.city.label')}
                                placeholder={i18n.t('forms.city.placeholder')}
                                onChangeText={onChange}
                                onBlur={onBlur}
                                value={value}
                                style={webViewDesignSupport}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="legal_address.state"
                        rules={{
                            required: {
                                value: 'true',
                                message: i18n.t('forms.common.required'),
                            },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                InputComponent={Dropdown}
                                data={provinces.concat(states.sort())}
                                labelField="name"
                                valueField="abbreviation"
                                errorMessage={
                                    errors.legal_address &&
                                    errors.legal_address.state &&
                                    errors.legal_address.state.message
                                }
                                label={i18n.t('forms.state.label')}
                                placeholder={i18n.t('forms.state.placeholder')}
                                placeholderStyle={{ opacity: 0.5 }}
                                onChange={({ abbreviation }) =>
                                    onChange(abbreviation)
                                }
                                onBlur={onBlur}
                                value={value}
                                style={webViewDesignSupport}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="legal_address.postal_code"
                        rules={{
                            required: {
                                value: 'true',
                                message: i18n.t('forms.common.required'),
                            },
                            pattern: {
                                value: /^([0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9])$/,
                                message: i18n.t(
                                    'forms.postalCode.invalidFormat'
                                ),
                            },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                                errorMessage={
                                    errors.legal_address &&
                                    errors.legal_address.postal_code &&
                                    errors.legal_address.postal_code.message
                                }
                                keyboardType="phone-pad"
                                label={i18n.t('forms.postalCode.label')}
                                placeholder={i18n.t(
                                    'forms.postalCode.placeholder'
                                )}
                                onChangeText={onChange}
                                onBlur={onBlur}
                                value={value}
                                defaultValue={value}
                                style={webViewDesignSupport}
                            />
                        )}
                    />
                    <CheckBox
                        checkedIcon={
                            <Icon
                                color={theme.colors.phthaloBlue}
                                type="ionicon"
                                name="checkbox"
                            />
                        }
                        uncheckedIcon={
                            <Icon
                                color={theme.colors.phthaloBlue}
                                type="ionicon"
                                name="square-outline"
                            />
                        }
                        containerStyle={webViewDesignSupport}
                        title={i18n.t('pages.profile.editAddress.sameAddress')}
                        checked={sameAddress}
                        onPress={() => setSameAddress(!sameAddress)}
                    />
                </View>
                {!sameAddress ? (
                    <View>
                        <Text h2>{i18n.t('pages.profile.mailingAddress')}</Text>
                        <Controller
                            control={control}
                            name="shipping_address.address_line_1"
                            rules={{
                                required: {
                                    value: 'true',
                                    message: i18n.t('forms.common.required'),
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    errorMessage={
                                        errors.shipping_address &&
                                        errors.shipping_address
                                            .address_line_1 &&
                                        errors.shipping_address.address_line_1
                                            .message
                                    }
                                    label={i18n.t('forms.streetAddress1.label')}
                                    placeholder={i18n.t(
                                        'forms.streetAddress1.placeholder'
                                    )}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="shipping_address.address_line_2"
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    errorMessage={
                                        errors.shipping_address &&
                                        errors.shipping_address
                                            .address_line_2 &&
                                        errors.shipping_address.address_line_2
                                            .message
                                    }
                                    label={i18n.t('forms.streetAddress2.label')}
                                    placeholder={i18n.t(
                                        'forms.streetAddress2.placeholder'
                                    )}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="shipping_address.city"
                            rules={{
                                required: {
                                    value: 'true',
                                    message: i18n.t('forms.common.required'),
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    errorMessage={
                                        errors.shipping_address &&
                                        errors.shipping_address.city &&
                                        errors.shipping_address.city.message
                                    }
                                    label={i18n.t('forms.city.label')}
                                    placeholder={i18n.t(
                                        'forms.city.placeholder'
                                    )}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="shipping_address.state"
                            rules={{
                                required: {
                                    value: 'true',
                                    message: i18n.t('forms.common.required'),
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    InputComponent={Dropdown}
                                    data={provinces.concat(states.sort())}
                                    labelField="name"
                                    valueField="abbreviation"
                                    errorMessage={
                                        errors.shipping_address &&
                                        errors.shipping_address.state &&
                                        errors.shipping_address.state.message
                                    }
                                    label={i18n.t('forms.state.label')}
                                    placeholder={i18n.t(
                                        'forms.state.placeholder'
                                    )}
                                    placeholderStyle={{ opacity: 0.5 }}
                                    onChange={({ abbreviation }) =>
                                        onChange(abbreviation)
                                    }
                                    onBlur={onBlur}
                                    value={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name="shipping_address.postal_code"
                            rules={{
                                required: {
                                    value: 'true',
                                    message: i18n.t('forms.common.required'),
                                },
                                pattern: {
                                    value: /^([0-9]{5}|[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9])$/,
                                    message: i18n.t(
                                        'forms.postalCode.invalidFormat'
                                    ),
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Input
                                    errorMessage={
                                        errors.shipping_address &&
                                        errors.shipping_address.postal_code &&
                                        errors.shipping_address.postal_code
                                            .message
                                    }
                                    keyboardType="phone-pad"
                                    label={i18n.t('forms.postalCode.label')}
                                    placeholder={i18n.t(
                                        'forms.postalCode.placeholder'
                                    )}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    defaultValue={value}
                                    style={webViewDesignSupport}
                                />
                            )}
                        />
                    </View>
                ) : null}
                <Button
                    buttonStyle={theme.styles.buttonStylePrimary}
                    titleStyle={theme.styles.titleStylePrimary}
                    disabled={!isValid}
                    title={i18n.t('forms.common.save')}
                    onPress={handleSubmit(onSubmit)}
                />
            </View>
        </ScrollableScreenLayout>
    );
};
