import { Button, Input } from 'react-native-elements';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { AlreadyMemberLink } from './components/already-member.component';
import { MainContext } from '../../../App.context';
import { OnboardingPageLayout } from './components/onboarding-page-layout.component';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from '../../common/ui/theme';
import { useToast } from 'react-native-toast-notifications';
import { webViewDesignSupport } from '../../common/utils/secure-store.utils';
import { useAuth0Actions } from '../../hooks/useAuth0Actions';

export const SignUp = () => {
    
    return (
        <MainContext.Consumer>
            {({ onError }) => (
                <SafeAreaView style={{ flex: 1 }}>
                    <OnboardingPageLayout
                        title={i18n.t('pages.signup.title')}
                        subtitle={i18n.t('pages.signup.subtitle')}
                        screenName="signupScreen"
                    >
                        <SignUpFrom onError={onError} />
                        <AlreadyMemberLink
                            textNote="rgba(0, 0, 0, 0.6)"
                            textLink="#00008E"
                        />
                    </OnboardingPageLayout>
                </SafeAreaView>
            )}
        </MainContext.Consumer>
    );
};

const SignUpFrom = ({ onError }) => {
    const {signUp} = useAuth0Actions();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm({
        mode: 'onChange',
        defaultValues: { email: '', password: '', confirmPassword: '' },
    });
    const focusInput = true;
    const toast = useToast();

    return (
        <View>
            <Controller
                control={control}
                name="email"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    pattern: {
                        value: /^(.+)@(.+)\.(.{2,6})$/,
                        message: i18n.t('forms.email.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        errorMessage={
                            focusInput && errors.email && errors.email.message
                        }
                        keyboardType="email-address"
                        label={i18n.t('forms.email.label')}
                        placeholder={i18n.t('forms.email.placeholder')}
                        autoCapitalize="none"
                        autoCorrect={false}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        autoFocus={true}
                    />
                )}
            />

            <Controller
                control={control}
                name="password"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    minLength: {
                        value: 6,
                        message: i18n.t('forms.password.invalidFormat'),
                    },
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        secureTextEntry={!showPassword}
                        errorMessage={
                            errors.password && errors.password.message
                        }
                        label={i18n.t('forms.password.label')}
                        placeholder={i18n.t('forms.password.placeholder')}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        style={webViewDesignSupport}
                        value={value}
                        rightIcon={{
                            type: 'material-community',
                            name: showPassword
                                ? 'eye-off-outline'
                                : 'eye-outline',
                            onPress: () => setShowPassword(!showPassword),
                        }}
                    />
                )}
            />

            <Controller
                control={control}
                name="confirmPassword"
                rules={{
                    required: {
                        value: 'true',
                        message: i18n.t('forms.common.required'),
                    },
                    validate: (value) =>
                        value === watch('password')
                            ? true
                            : i18n.t('forms.confirmPassword.mustMatch'),
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                        autoCapitalize="none"
                        secureTextEntry={!showPasswordConfirm}
                        errorMessage={
                            errors.confirmPassword &&
                            errors.confirmPassword.message
                        }
                        label={i18n.t('forms.confirmPassword.label')}
                        placeholder={i18n.t(
                            'forms.confirmPassword.placeholder'
                        )}
                        onChangeText={onChange}
                        onBlur={onBlur}
                        value={value}
                        style={webViewDesignSupport}
                        rightIcon={{
                            type: 'material-community',
                            name: showPasswordConfirm
                                ? 'eye-off-outline'
                                : 'eye-outline',
                            onPress: () => setShowPasswordConfirm(!showPasswordConfirm),
                        }}
                    />
                )}
            />
            <Button
                buttonStyle={{
                    backgroundColor: '#00008E',
                    padding: 13,
                    borderRadius: 4,
                    shadowColor: '#00008E',
                    borderWidth: 2,
                    borderColor: '#00008E',
                    shadowRadius: 5,
                    shadowOpacity: 0.7,
                    shadowOffset: { height: 5 },
                }}
                loading={isLoading}
                disabled={!isDirty || !isValid}
                title={i18n.t('common.signup')}
                titleStyle={theme.styles.titleStylePrimary}
                onPress={handleSubmit((formData) => {
                    setLoading(true);
                    signUp(formData).catch((e) => {
                        toast.show(e.json.message, {
                            type: 'dangerWithTitle',
                            data: {
                                title: i18n.t('common.errorTitle'),
                            },
                        });
                        onError(e.json.message);
                        setLoading(false);
                    });
                })}
            />
        </View>
    );
};

SignUpFrom.propTypes = {
    onSubmit: PropTypes.func,
    onError: PropTypes.func,
};
